
    .dataBtn {
        display: inline-block;
        padding: 8px 28px;
        background-color: #F23B2F;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        margin: 18px 38px;
        cursor: pointer;
    }

    .customerInfoLayout {
        width: 100%;
        display: flex;
        height: calc(100vh - 50px);

        .customerInfoLayout_left {
            width: 200px;
            min-width: 200px;
            box-shadow: 0px 0px 16px 0px rgba(101, 30, 30, 0.1000);
        }

        .customerInfoLayout_right {
            flex: 0.99;
        }
    }

    .textColor {
        color: #F23B2F;
    }

    .el-menu {
        border: none;
    }

    .submitBtn {
        display: inline-block;
        color: #FFFFFF;
        padding: 10px 28px;
        background-color: #F23B2F;
        border-radius: 4px 4px 4px 4px;
        position: absolute;
        bottom: 90px;
        left: 52px;
        cursor: pointer;
    }
